import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSending } from '../formSending';
import toast from 'react-hot-toast';
import { invoices } from '../../../services';
import { useGetAllStatements } from '../../../hooks/statements';
import { useEffect, useState } from 'react';
import { useUserContext } from '../../../providers/user/useUserContext';
import {
  MAX_FILE_SIZE,
  VALID_FILE_EXTENSIONS,
} from '../../../constants/fileUploadRules';
import { files } from '../../../helpers';
import { FormInfo } from '../formInfo';
import FileUpload from '../fileUpload/fileUpload';
import { useParams } from 'react-router';

const AddInvoice = ({ type, setModalActiveState, statement, id }) => {
  const { user, hsId } = useUserContext();
  const { accountId } = useParams();
  const { statementsData, isStatementsLoading } = useGetAllStatements();
  const [formStatus, setFormStatus] = useState('start');
  const [maxTotal, setMaxTotal] = useState(0);

  useEffect(() => {
    if (statement) {
      setMaxTotal(statement.total);
    } else if (statementsData && statementsData.results.length > 0) {
      setMaxTotal(statementsData.results[0].total);
    }
  }, [statement, statementsData]);

  useEffect(() => {
    if (!statement && statementsData && statementsData.results.length > 0) {
      // Update maxTotal if statementsData is loaded after initial render
      setMaxTotal(statementsData.results[0].total);
    }
  }, [statementsData]);

  const handleStatementChange = (e) => {
    const selectedStatement = statementsData.results.find(
      (stmt) => stmt.id === e.target.value
    );
    if (selectedStatement) {
      setMaxTotal(selectedStatement.total);
    }
  };

  const schema = Yup.object().shape({
    invoiceReference: Yup.string().required('Invoice reference is required'),
    invoiceDate: Yup.string().required('Invoice date is required'),
    invoiceTotal: Yup.string()
      .required('Invoice total is required')
      .test(
        'no-commas',
        'Invoice Total cannot contain commas',
        (value) => !/,/.test(value)
      )
      .test(
        'numbers-only',
        'Invoice total must be a number and can contain decimals',
        (value) => /^\d+(\.\d+)?$/.test(value)
      )
      .test(
        'positive-number',
        'Invoice total must be a positive number',
        (value) => parseFloat(value) > 0
      )
      .test(
        'max-total',
        `The total invoice amount exceeds your statement amount £${maxTotal}, please check your statement total`,
        (value) => parseFloat(value) <= maxTotal
      ),
    [`attachment_${id}`]: Yup.mixed()
      .required('Attachment is required')
      .test(
        'is-valid-type',
        `Please upload one of the following allowed file types: ${VALID_FILE_EXTENSIONS.join(
          ', '
        )}`,
        (value) => files.isValidFileType(value && value.name.toLowerCase())
      )
      .test(
        'is-valid-size',
        'Max allowed size is 10MB',
        (value) => value && value.size <= MAX_FILE_SIZE
      ),
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitForm = async (formData) => {
    setFormStatus('sending');
    const sendingToast = toast.loading('Creating invoice...');

    const response = await invoices.createInvoice({
      hsId: hsId,
      firstname: user.given_name,
      lastname: user.family_name,
      values: formData,
      companyId: accountId || null,
    });

    if (setModalActiveState) {
      setModalActiveState(false);
    }

    if (response.status === 'error') {
      toast.dismiss(sendingToast);
      toast.error(response.message);
    } else {
      toast.dismiss(sendingToast);
      toast.success('Success');
    }

    reset();
    setFormStatus('start');
  };

  return (
    <>
      <h2>Add invoice</h2>
      {formStatus === 'start' && (
        <form onSubmit={handleSubmit(submitForm)}>
          <FormInfo message='Please be aware that you cannot submit an invoice for a statement that is yet to be issued.' />

          {type === 'invoices-page' && (
            <div className='form-group'>
              <label htmlFor='statementNumber'>Select Statement</label>
              {isStatementsLoading ? (
                <>Loading statements...</>
              ) : (
                <div className='select-wrapper'>
                  <select
                    id='statementNumber'
                    {...register('statementNumber')}
                    onChange={handleStatementChange}
                  >
                    <option disabled value=''>
                      Select statement
                    </option>
                    {statementsData &&
                    statementsData.results &&
                    Array.isArray(statementsData.results) ? (
                      statementsData.results.map((statement, key) => (
                        <option key={key} value={statement.id}>
                          {statement.statementNumber}
                        </option>
                      ))
                    ) : (
                      <option value=''>No statements available</option>
                    )}
                  </select>
                </div>
              )}
            </div>
          )}
          {statement && type === 'statements-page' && (
            <>
              <div className='form-group'>
                <label htmlFor='statementNumberRef'>Statement number</label>
                <input
                  type='text'
                  className='form-control'
                  readOnly
                  value={statement.statementNumber}
                />
              </div>
              <div className=''>
                <input
                  type='text'
                  className='form-control'
                  {...register('statementNumber', {
                    defaultValue: statement.id,
                  })}
                  readOnly
                  hidden
                  value={statement.id}
                />
              </div>
            </>
          )}
          <div className='form-group'>
            <label htmlFor='invoiceReference'>Unique invoice reference</label>
            <input
              id='invoiceReference'
              type='text'
              className={`form-control ${
                errors.invoiceReference && 'has-error'
              }`}
              {...register('invoiceReference')}
              placeholder='Unique invoice reference'
            />
            {errors.invoiceReference && (
              <p className='form-error'>{errors.invoiceReference?.message}</p>
            )}
          </div>
          <div className='form-group'>
            <label htmlFor='date'>Date</label>
            <Controller
              control={control}
              name='invoiceDate'
              render={({ field }) => (
                <input
                  id='date'
                  type='date'
                  className={`form-control ${
                    errors.invoiceDate && 'has-error'
                  }`}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value}
                />
              )}
            />
            {errors.invoiceDate && (
              <div className='form-error'>{errors.invoiceDate?.message}</div>
            )}
          </div>
          <div className='form-group'>
            <label htmlFor='invoiceTotal'>Net total (excluding VAT)</label>
            <input
              id='invoiceTotal'
              className={`form-control ${errors.invoiceTotal && 'has-error'}`}
              type='text'
              {...register('invoiceTotal')}
              placeholder='£'
            />
            {errors.invoiceTotal && (
              <div className='form-error'>{errors.invoiceTotal?.message}</div>
            )}
          </div>
          <FileUpload setValue={setValue} errors={errors} id={id} />
          <input
            className={`button button--full button-primary--blue ${
              statementsData?.results.length === 0 ? 'is-disabled' : ''
            }`}
            type='submit'
            value='Submit'
            disabled={statementsData?.results.length === 0 ? 'true' : false}
          />
        </form>
      )}
      {formStatus === 'sending' && (
        <FormSending
          title='Submitting invoice'
          message='Please wait whilst we submit your invoice'
        />
      )}
    </>
  );
};

AddInvoice.propTypes = {
  type: PropTypes.string.isRequired,
  statement: PropTypes.object,
  setModalActiveState: PropTypes.func,
  id: PropTypes.string.isRequired,
};

export default AddInvoice;

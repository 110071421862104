// Hooks
import { useGetContracts } from '../../../hooks/contracts';

// Form stuff
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Toast stuff
import toast from 'react-hot-toast';

// Files
import {
  VALID_FILE_EXTENSIONS,
  MAX_FILE_SIZE,
} from '../../../constants/fileUploadRules';
import { files } from '../../../helpers';
import { FileUpload } from '../fileUpload';

import {
  ForecastFormData,
  ForecastsFormProps,
} from '../../../features/energyGenerators/forecasts/types';

import { useState } from 'react';
import { FormSending } from '../formSending';
import FormSuccess from '../formSuccess/formSuccess';

type ContractDropdownItem = {
  id: string;
  contract_account_name: string;
};

const ForecastsForm = ({
  formSubmitHandler,
  id,
  setModalActiveState,
}: ForecastsFormProps) => {
  const { contractsData, isContractsLoading } = useGetContracts();
  const [formStatus, setFormStatus] = useState('start');

  const schema = yup.object({
    forecastFrom: yup.string().required('Forecast from is required'),
    forecastTo: yup.string().required('Forecast to is required'),
    forecastSiteName: yup.string().required('Forecast site name is required'),
    forecastReference: yup.string().required('Forecast reference is required'),
    forecastTotal: yup
      .string()
      .required('Forecast total is required')
      .test(
        'no-commas',
        'Invoice Total cannot contain commas',
        (value) => !/,/.test(value ?? '')
      ),
    [`attachment_${id}`]: yup
      .mixed()
      .required('Attachment is required')
      .test(
        'is-valid-type',
        `Please upload one of the following allowed file types: ${VALID_FILE_EXTENSIONS.join(
          ', '
        )}`,
        (value) =>
          files.isValidFileType(value && value.name.toLowerCase()) || !value
      )
      .test(
        'is-valid-size',
        'Max allowed size is 10MB',
        (value) => (value && value.size <= MAX_FILE_SIZE) || !value
      ),
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (data: FormData) => {
    setFormStatus('sending');
    try {
      formSubmitHandler(data as unknown as ForecastFormData);
      reset();
      setFormStatus('sent');
    } catch (err) {
      console.error(err);
    }
  };

  const handleForm = (data: unknown) => {
    toast.promise(handleFormSubmit(data as FormData), {
      loading: 'Saving...',
      success: () => `Successfully created forecast`,
      error: () => `There was an error uploading your forecast`,
    });
    setModalActiveState(false);
    reset();
  };

  return (
    <>
      <h2>Add Forecast</h2>
      {formStatus === 'start' && (
        <form onSubmit={handleSubmit(handleForm)}>
          <div className='form-group form-group--select'>
            <label htmlFor='contract_id'>Select Contract</label>
            <div className='select-wrapper'>
              <select id='contract_id' {...register('contract_id')}>
                {isContractsLoading ? (
                  <option selected disabled value=''>
                    Loading contracts
                  </option>
                ) : null}
                {!isContractsLoading && contractsData.results.length > 0 ? (
                  <>
                    <option selected disabled value=''>
                      Select contract
                    </option>
                    {contractsData.results.map(
                      (contract: ContractDropdownItem) => (
                        <option key={contract.id} value={contract.id}>
                          {contract.contract_account_name}
                        </option>
                      )
                    )}
                  </>
                ) : (
                  <>
                    <option selected disabled value=''>
                      No Contracts
                    </option>
                  </>
                )}
              </select>
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='forecastFrom'>Forecast from</label>
            <Controller
              control={control}
              name='forecastFrom'
              render={({ field }) => (
                <input
                  id='forecastFrom'
                  type='date'
                  className={`form-control ${
                    errors.forecastFrom && 'has-error'
                  }`}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value}
                />
              )}
            />
            {errors.forecastFrom && (
              <div className='form-error'>{errors.forecastFrom?.message}</div>
            )}
          </div>
          <div className='form-group'>
            <label htmlFor='forecastTo'>Forecast to</label>
            <Controller
              control={control}
              name='forecastTo'
              render={({ field }) => (
                <input
                  id='forecastTo'
                  type='date'
                  className={`form-control ${errors.forecastTo && 'has-error'}`}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value}
                />
              )}
            />
            {errors.forecastTo && (
              <div className='form-error'>{errors.forecastTo?.message}</div>
            )}
          </div>

          <div className='form-group'>
            <label htmlFor='forecastSiteName'>Site name</label>
            <input
              id='forecastSiteName'
              className={`form-control ${
                errors.forecastSiteName && 'has-error'
              }`}
              type='text'
              {...register('forecastSiteName')}
              placeholder='Site name'
            />
            {errors.forecastSiteName && (
              <div className='form-error'>
                {errors.forecastSiteName?.message}
              </div>
            )}
          </div>

          <div className='form-group'>
            <label htmlFor='forecastReference'>
              Customer forecast reference
            </label>
            <input
              id='forecastReference'
              className={`form-control ${
                errors.forecastReference && 'has-error'
              }`}
              type='text'
              {...register('forecastReference')}
              placeholder='Forecast reference'
            />
            {errors.forecastReference && (
              <div className='form-error'>
                {errors.forecastReference?.message}
              </div>
            )}
          </div>

          <div className='form-group'>
            <label htmlFor='forecastTotal'>Total forecasted kWh</label>
            <input
              id='forecastTotal'
              className={`form-control ${errors.forecastTotal && 'has-error'}`}
              type='text'
              {...register('forecastTotal')}
              placeholder='Total forecasted kWh'
            />
            {errors.forecastTotal && (
              <div className='form-error'>{errors.forecastTotal?.message}</div>
            )}
          </div>

          <FileUpload setValue={setValue} errors={errors} id={id} />

          <input
            className='button button--full button-primary--blue'
            type='submit'
            value='Submit'
          />
        </form>
      )}
      {formStatus === 'sending' && (
        <FormSending
          title='Submitting forecast'
          message='Please wait whilst we submit your document'
        />
      )}

      {formStatus === 'sent' && (
        <FormSuccess
          title='Success'
          message='Your document has been submitted'
        />
      )}
    </>
  );
};

export { ForecastsForm };

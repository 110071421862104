import { useEffect } from 'react';
import { useUserContext } from '../user/useUserContext';
import * as chameleon from '@chamaeleonidae/chmln';

const ChameleonInitializer = () => {
  const { isAuthenticated, user, hsId } = useUserContext();
  const isLoginPage = window.location.pathname === '/';
  useEffect(() => {
    let initialized = false;
    if (user && isAuthenticated && hsId !== '') {
      chameleon.init(process.env.REACT_APP_CHAMELEON_API_KEY, {
        fastUrl: 'https://fast.chameleon.io/',
      });
      console.log('Chameleon initialized', user);
      // Handle missing given_name or family_name
      chameleon.identify(hsId, {
        email: user.email,
        name: user.nickname,
      });
      initialized = true;
    } else if (isLoginPage && !initialized) {
      chameleon.init(process.env.REACT_APP_CHAMELEON_API_KEY, {
        fastUrl: 'https://fast.chameleon.io/',
      });

      var uid;
      try {
        uid = document.cookie.match(/\bvisitor-uid=([a-z0-9-]+)(;|$)/)[1];
      } catch (e) {
        document.cookie =
          'visitor-uid=' +
          (uid = Math.random().toString(36).substring(2)) +
          '; expires=Tue, Oct 13 2037 04:24:07 UTC; path=/;';
      }

      chameleon.identify(uid, { visitor: true });
    }
  }, [user]);

  return null;
};

export default ChameleonInitializer;

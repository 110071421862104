import PropTypes from 'prop-types';
import { IonCol } from '@ionic/react';
import styled from 'styled-components';

const OpeningHours = styled.p`
  margin-top: 11.5rem;
  font-size: 15px;
  font-size: 1.5rem;
  color: #4f4f50;
`;
function CtaBlockOpeningHours(props) {
  const { contactOpeningHours, callChargeText, callChargeLink } = props;

  const displayOpeningHours = () => {
    if (contactOpeningHours) {
      return <OpeningHours>{contactOpeningHours}</OpeningHours>;
    }
    return (
      <OpeningHours>
        We&apos;re open:
        <br />
        <br />
        Monday, Tuesday, Thursday 9am-5pm
        <br />
        Wednesday 9am-5pm
        <br />
        (Customer Services 9am-12.00pm)
        <br />
        Friday 9am-4.30pm
        <br />
        Excluding public holidays
      </OpeningHours>
    );
  };

  const displayCallCharge = () => {
    if (callChargeText) {
      return (
        <p>
          <a href={callChargeLink}>{callChargeText}</a>
        </p>
      );
    }
    return (
      <p>
        <a
          href='https://npowerbusinesssolutions.com/information/phone-charges'
          target='_blank'
          rel='noreferrer'
        >
          View call charge information
        </a>
      </p>
    );
  };

  return (
    <IonCol>
      {displayOpeningHours()}
      {displayCallCharge()}
    </IonCol>
  );
}
CtaBlockOpeningHours.propTypes = {
  contactOpeningHours: PropTypes.string,
  callChargeText: PropTypes.string,
  callChargeLink: PropTypes.string,
};
export default CtaBlockOpeningHours;

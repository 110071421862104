import { useUserContext } from '../../../providers/user/useUserContext';

import {
  DashboardContentStyled,
  DashboardLogoStyled,
  DashboardVideoStyled,
} from '../styles/dashboardContent.styles';
const DashboardContent = () => {
  const { user } = useUserContext();

  return (
    <DashboardContentStyled>
      <div>
        <h1>
          Welcome {user && user.given_name ? <> {user.given_name}</> : null}
        </h1>

        <p>
          See all your energy transactions at a glance, and easily manage
          forecasts, invoices and more.
        </p>
      </div>
      <DashboardLogoStyled></DashboardLogoStyled>
      <DashboardVideoStyled>
        <video controls width='100%'>
          <source
            src='https://24995540.fs1.hubspotusercontent-eu1.net/hubfs/24995540/Content,%20Social,%20PR%20and%20Comms/Social/Social%20Videos%202023/npm15842%20MM22091_DESNZ_Vish%20Sharma.mp4'
            type='video/mp4'
          />
        </video>
      </DashboardVideoStyled>
    </DashboardContentStyled>
  );
};

export { DashboardContent };
